@media (prefers-color-scheme: light) {
  body {
    background: rgb(255, 255, 255);
  }
  #tsparticles {
    visibility: hidden;
    opacity: 0;
  }
  body,
  input,
  select,
  textarea,
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button,
  .button {
    color: black;
  }
  li,
  p,
  span,
  label,
  input,
  select,
  textarea,
  img,
  div,
  svg,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
  }
  .post {
    background: #e3e3e3cb;
  }
  #header,
  .bm-menu,
  .mini-post {
    background: #e3e3e3fa;
  }
  .cell-container .description p {
    background: #e3e3e3a2;
    color: black;
  }
  .cell-container .description p:hover {
    background: #e3e3e380;
  }
}
li,
p,
span,
label,
input,
select,
textarea,
img,
div,
svg,
h1,
h2,
h3,
h4,
h5,
h6,
input[type="submit"],
input[type="reset"],
input[type="button"],
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}
button {
  border: 0;
}
.h-full {
  height: 100%;
}
@media (min-width: 1440px) {
  .mini-post {
    max-height: 844px;
  }
}
@media (max-width: 767px) {
  .image-slider {
    max-height: 234px;
    height: 234px;
  }
}
